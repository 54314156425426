.App {
  text-align: center;
  background-color: #015c53;
  min-height: 100vh;
  min-width: auto;
}

.pufferfish-logo {
  height: auto;
  width: 50vw;

}

.coming-soon-text {
  color: #e46725;
}